h2 {
    font-weight: bold;
    color: #333;
  }
  
  /* src/components/ContactUs.css */
.contact-us-bg {
    background-image: url('../../../public/assets/images/contactUs.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh; /* Full height to ensure vertical centering */
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
  }
  
  .contact-us {
    margin: 0;
    font-weight: 900;
    padding: 20px;
    color: var(--primary-color); /* Adjust text color for better visibility */
  }

  .contact-heading{
    color: var(--primary-color);
    text-shadow: 2px 2px 5px  var(--secondary-color);
  }
  

  /* Slide-in from left */
.slide-in-left {
    animation: slideInLeft 1s forwards;
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Slide-in from right */
  .slide-in-right {
    animation: slideInRight 1s forwards 0.5s; /* 0.5s delay */
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .contact-text{
 color: var(--primary-color) ;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 1rem;
  }
  .list-item-spacing {
    color: var(--primary-color);
    margin-bottom: 1rem;
    list-style: none;
  }

  .form-label{
    color: var(--primary-color);
  }
  