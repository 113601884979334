.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: var(--primary-color);
  }
  
  .not-found-heading {
    font-size: 10rem;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found-button {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
  