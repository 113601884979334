.logo-img {
  max-height: 150px; /* Define the maximum height of the logo */
  max-width: 150px; /* Define the maximum width of the logo */
}
.navbar-collapse {
  margin-left: 30%;
}
.navbar{
  background-color: var(--background-color);
  /* Set maximum height to viewport height minus navbar height */
}
.nav-link,
.nav-link:hover {
  color:  var(--secondary-color); /* White text color */
}
.navbar-nav .nav-link {
  position: relative; /* Ensure relative positioning */
}

.navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 1s ease;
}

.navbar-nav .nav-link:hover::before {
  width: 100%;
}

.navbar-toggler {
  outline: none !important; /* Remove button outline */
}

#servicesDropdown:hover .dropdown-menu {
  display: block;
  background-color: var(--background-color);
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}
.dropdown-menu {
  background-color: var(--background-color);
  border-top-left-radius: 0; /* Adjust border-radius if needed */
  max-height: calc(
    100vh - 70px
  ); /* Set maximum height to viewport height minus navbar height */
}

.dropdown-menu .dropdown-item {
  color:  var(--secondary-color); /* White text color */
}

.dropdown-item:hover {
  background-color: #36454f; /* Darker background-color */
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem; /* Adjust padding for better spacing */
}
.contactBtn {
  background-color: transparent; /* Set background color to transparent */
  color: var(--primary-color); /* Set text color */
  border: 2px solid var(--primary-color); /* Add border */
  border-radius: 0; /* Adjust border-radius if needed */
  transition: background-color 0.3s, color 0.3s; /* Add transition */
}

.contactBtn:hover {
  background-color: var(--primary-color); /* Change background color on hover */
  color:  var(--secondary-color); /* Change text color on hover */
}

.dropdown-menu .dropdown-item {
  position: relative; /* Ensure relative positioning */
  color:  var(--secondary-color); /* Default text color */
}

.dropdown-menu .dropdown-item::before {
  content: ""; /* Create pseudo-element for the bottom border */
  position: absolute; /* Position the pseudo-element */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Initial position to start from the left */
  width: 0; /* Initially, the width is 0 */
  height: 2px; /* Height of the bottom border */
  background-color: var(--primary-color); /* Color of the bottom border */
  transition: width 1s ease; /* Define transition property */
}

.dropdown-menu .dropdown-item:hover::before {
  width: 100%; /* Increase width to 100% on hover */
}

/* Style for active link in dropdown */
.dropdown-menu .dropdown-item.active {
  background-color: var(--background-color); /* Background color for active link */
}

/* Style for hover effect in dropdown */
.dropdown-menu .dropdown-item:hover {
  background-color: var(--background-color); /* Background color for hover */
}

/* Style for bottom border */
.dropdown-menu .dropdown-item::before {
  content: ""; /* Create pseudo-element for the bottom border */
  position: absolute; /* Position the pseudo-element */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Initial position to start from the left */
  width: 0; /* Initially, the width is 0 */
  height: 2px; /* Height of the bottom border */
  background-color: var(--primary-color); /* Color of the bottom border */
  transition: width 0.5s ease; /* Define transition property */
}

/* Style for active link */
.navbar-nav .nav-link.active::before {
  width: 100%; /* Width set to 100% */
}

.navbar-nav .nav-link {
  color:  var(--secondary-color); /* Set the default text color */
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color:  var(--secondary-color); /* Set the text color for active, focus, and hover states */
}
.container1 {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: var(--primary-color); /* Changed from #333 to var(--primary-color) */
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}


@media (max-width: 991.98px) {
  /* Adjusted margin-left for navbar-collapse */
  .navbar-collapse {
    margin-left: 0; /* Adjusted to align with the left side */
  }
}
/* For larger screens (desktops) */

/* For medium screens (tablets) */
@media (max-width: 991.98px) and (min-width: 768px) {
  /* Styles for tablet screens */
  .text-container {
    position: absolute;
    top: 35%; /* Place the top of the container at the vertical center */
    left: 20%; /* Adjust left position as needed */
    transform: translate(
      -50%,
      -50%
    ); /* Use transform to center both vertically and horizontally */
    z-index: 1; /* Ensure the text appears above the video */
    color: white; /* Adjust text color as needed */
  }

  /* Style for the text */
  .text {
    margin-top: 20vh;
    font-size: 45px;
    font-weight: 700; /* Adjust font weight as needed */
    margin-left: 30%; /* Adjust font size as needed */
  }

  .description {
    width: 70%;
  }
}

/* General rule for all elements to prevent overflow */
* {
  box-sizing: border-box;
}
.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  margin-bottom: 5px;
}

.dropdown-menu ul li a {
  color:  var(--secondary-color);
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}

.menu-item-d::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 1s ease;
}
.menu-item-d:hover::before {
  width: 100%;
}



.text-coration{
  text-decoration: none;
}
.contact-us-btn{
margin-top: 10px;
}