/* Header.css */
header {
    background: #fff;
    color: #333;

}

header h1 {
    font-size: 2.5rem;
}

header .text-warning {
    color: #ffae00 !important;
}

header p {
    font-size: 1.25rem;
}

header img {
    width: 50px;
    height: auto;
}

header button {
    padding: 10px 20px;
    font-size: 1rem;
}

.header-heading{
    font-weight: 900;
}
.marginTop{
    margin-top: 10rem;
}

@media screen and (max-width: 768px) {
    .marginTop{
        margin-top: 5rem;
    }
    
  }

  @media screen and (max-width: 576px) {
    .marginTop{
        margin-top: 2rem;
    }
    
  }

  .text-coration{
    text-decoration: none;
  }
  .contact-us-btn{
  margin-top: 10px;
  }