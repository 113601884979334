/* App.css */
:root {
    --primary-color: #fc9424;
    --secondary-color: #0c8898;
    --background-color: #343a40;
    --light-background-color: #f8f9fa;
}
*{
  font-family:Arial, Helvetica, sans-serif;  
}
.primary-color {
    color: var(--primary-color);
}

.secondary-color {
    color: var(--secondary-color);
}

.background-color {
    color: var(--background-color);
}

.light-background-color {
    background-color: var(--light-background-color);
}
.primary-btn {
    background-color: transparent;
    color: var(--primary-color); 
    border: 2px solid var(--primary-color); 
    border-radius: 0; 
    transition: background-color 0.3s, color 0.3s; 
    font-weight: 700;
  }
  
  .primary-btn:hover {
    background-color: var(--primary-color); 
    color:  var(--secondary-color);
  }
