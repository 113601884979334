/* AboutUs.css */
.about-us-bg {
  background-image: url('../../../public/assets/images/aboutUs.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Adjust based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  color: white;
}

.contact-heading {
  margin-bottom: 1rem;
}

.contact-us {
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.mission-section, .vision-section {
  padding: 60px 0;
}

.mission-heading, .vision-heading {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.mission-section .lead, .vision-section .lead {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.mission-card, .vision-card {
  margin-bottom: 1rem;
}

.about-us-section {
  background-color: var(--background-color);
}

.card-padding {
  padding: 100px, 50px !important;
}

/* Hide images on smaller screens */
@media (max-width: 767.98px) {
  .d-md-block {
    display: none !important;
  }
}
