.service-card {
    background-color: #f8f9fa;
    border: none;
    transition: transform 0.01s ease;
    
  }
  
  .service-card:hover {
    transform: scale(1.10) !important;
    background-color: #e9ecef;
  }
  
   .card-body {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.custom-height {
    min-height: 200px;
  }